import React, { Component } from "react";
import PropTypes from "prop-types";

import { includes } from "ramda";
import { connect } from "react-redux";
import { compose } from "redux";

import { getAdempimentiConfig, resetAdempimentiConfig } from "../../actions/services/a-fiscali-iva";
import { resetCompanyLoad } from "../../actions/company";
import { getCompanies } from "../../actions/companies";
import { certificationInfo, uploadCert } from "../../actions/certification";
import { verifyStatus, companyPec } from "../../actions/contract";
import { getGdprLogRoleAssociation } from "../../actions/services/gdprlog";
import { fetchCctProvisioningInfo } from "../../actions/services/cct/provisioning";
import { getItemApps, getWorkspaceId } from "../../actions/services/app";
import { findOwnManagerConnections } from "../../actions/connections/read";
import { esmDiscover, esmOpenApp, partecipateTalks } from "../../actions/gainsightEvents";

import Dashboard from "../../components/dashboard/index";
import { ModalGdprContract } from "../../components/general/gdpr";
import { errorBoundary } from "../../components/general/error-boundary";

import { getConfig } from "../../actions/services/tspay";

class DashboardView extends Component {
    constructor() {
        super();
        this.state = {
            gdprUrl: null,
            gdprContractVisible: false
        };
    }
    static propTypes = {
        auth: PropTypes.object.isRequired,
        certificationInfo: PropTypes.func.isRequired,
        company: PropTypes.object.isRequired,
        companyPec: PropTypes.func.isRequired,
        configTsPay: PropTypes.object,
        contract: PropTypes.object.isRequired,
        coreMarketingTerms: PropTypes.bool,
        esmDiscover: PropTypes.func.isRequired,
        esmOpenApp: PropTypes.func.isRequired,
        fetchCctProvisioningInfo: PropTypes.func.isRequired,
        getAdempimentiConfig: PropTypes.func.isRequired,
        getCompanies: PropTypes.func.isRequired,
        getConfig: PropTypes.func.isRequired,
        getGdprLogRoleAssociation: PropTypes.func.isRequired,
        getItemApps: PropTypes.func.isRequired,
        getWorkspaceId: PropTypes.func.isRequired,
        hasStudio: PropTypes.bool,
        itemId: PropTypes.string.isRequired,
        itemUuid: PropTypes.string.isRequired,
        partecipateTalks: PropTypes.func.isRequired,
        pec: PropTypes.object.isRequired,
        provisioningInfo: PropTypes.object,
        rejectionMessage: PropTypes.string,
        resetAdempimentiConfig: PropTypes.func.isRequired,
        resetCompanyLoad: PropTypes.func.isRequired,
        services: PropTypes.object.isRequired,
        uploadCert: PropTypes.func.isRequired,
        user: PropTypes.object,
        verifyStatus: PropTypes.func.isRequired,
        hasActiveArcService: PropTypes.bool
    };

    static defaultProps = {
        provisioningInfo: {
            status: null
        }
    };

    componentDidMount() {
        const {
            certificationInfo,
            company,
            companyPec,
            contract,
            fetchCctProvisioningInfo,
            getAdempimentiConfig,
            getConfig,
            getGdprLogRoleAssociation,
            getItemApps,
            getWorkspaceId,
            itemId,
            itemUuid,
            pec,
            resetAdempimentiConfig,
            services,
            user,
            verifyStatus,
            isCctActive
        } = this.props;
        let companyData = company.base;
        if (companyData.status.status !== "VALIDATED") {
            certificationInfo(companyData.id);
        }

        isCctActive && fetchCctProvisioningInfo(companyData.id);
        getItemApps(itemId, user.profile.id);
        getWorkspaceId(itemUuid);
        getConfig(itemId);

        if (!contract.status.started) {
            verifyStatus(companyData.identifier.taxId, companyData.identifier.vatNumber).then(response => {
                if (response && response.code === "1") {
                    this.setState({
                        gdprContractVisible: true,
                        gdprUrl: response.uri
                    });
                }
            });
        }
        if (services.active.services) {
            if (!pec.status.started) {
                companyPec(company);
            }
            resetAdempimentiConfig();
            if (includes("A-FISCALI-IVA-FLOW", services.active.services)) {
                getAdempimentiConfig(companyData.id);
            }

            getGdprLogRoleAssociation(companyData.id);
        }
    }

    renderGDPRContract() {
        const { gdprContractVisible, gdprUrl } = this.state;
        return (
            <ModalGdprContract
                stateModalGdprContract={gdprContractVisible}
                handleCloseGdprContract={() =>
                    this.setState({
                        gdprContractVisible: false,
                        gdprUrl: null
                    })
                }
                contractUrl={gdprUrl}
            />
        );
    }

    render() {
        const {
            auth,
            categories,
            company,
            companyUsers,
            configTsPay,
            coreMarketingTerms,
            esmDiscover,
            esmOpenApp,
            logoUrl,
            logoFinishedLoading,
            findOwnManagerConnections,
            getCompanies,
            hasActiveCramConnection,
            hasStudio,
            itemApps,
            partecipateTalks,
            provisioningInfo,
            rejectionMessage,
            resetCompanyLoad,
            services,
            uploadCert,
            user,
            hasActiveArcService
        } = this.props;

        return (
            <div>
                <Dashboard
                    auth={auth}
                    categories={categories}
                    company={company}
                    companyUsers={companyUsers}
                    configTsPayStatus={configTsPay.apikeyStatus}
                    coreMarketingTerms={coreMarketingTerms}
                    esmDiscover={esmDiscover}
                    esmOpenApp={esmOpenApp}
                    findOwnManagerConnections={findOwnManagerConnections}
                    getCompanies={getCompanies}
                    hasActiveCramConnection={hasActiveCramConnection}
                    hasStudio={hasStudio}
                    itemApps={itemApps}
                    partecipateTalks={partecipateTalks}
                    provisioningInfo={provisioningInfo}
                    rejectionMessage={rejectionMessage}
                    resetCompanyLoad={() => {
                        resetCompanyLoad();
                    }}
                    services={services}
                    uploadCert={uploadCert}
                    user={user}
                    logoUrl={logoUrl}
                    logoFinishedLoading={logoFinishedLoading}
                    hasActiveArcService={hasActiveArcService}
                />
                {this.renderGDPRContract()}
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    const company = state.companies.data[props.match.params.item]
        ? state.companies.data[props.match.params.item].item
        : {};

    const rejectionMessage =
        company.base && state.certification.certificationInfo[company.base.id]
            ? state.certification.certificationInfo[company.base.id].message
            : "";

    const provisioningInfoArray = state.services.cct.provisioningInfo.provisioningInfo;

    let hasActiveCramConnection = false;

    if (state.company.status.ended && state.connectionsNew.read.findOwnManagerConnections.status.ended) {
        hasActiveCramConnection = state.connectionsNew.read.findOwnManagerConnections.content.some(manager =>
            manager.connections.some(ele => ele.appId === "CRAM" && ele.status.active === true)
        );
    }

    let hasActiveArcService = state.services.active.services.some(el => el === "ARC");

    return {
        auth: state.auth,
        categories: state.services.categories.data,
        companyUsers: state.companies.data[props.match.params.item]
            ? state.companies.data[props.match.params.item].users
            : {},
        company: company,
        configTsPay: state.tspay.tspayConfig,
        contract: state.contracts.contract,
        coreMarketingTerms: state.userConsents.terms.CORE_MARKETING && state.userConsents.terms.CORE_MARKETING.accepted,
        hasStudio: state.registry.read.getHasStudio && state.registry.read.getHasStudio.hasStudio,
        hasActiveCramConnection: hasActiveCramConnection,
        itemApps: state.services.itemApps.data,
        itemId: props.match.params.item,
        itemUuid: state.companies.data[props.match.params.item]
            ? state.companies.data[props.match.params.item].item.base.uuid
            : {},
        pec: state.contracts.pec,
        provisioningInfo: provisioningInfoArray[props.match.params.item],
        rejectionMessage: rejectionMessage,
        services: state.services,
        user: state.user.user,
        logoUrl: state.manageLogo.logos[company.base.uuid],
        logoFinishedLoading: state.manageLogo.getLogo.ended,
        hasActiveArcService,
        isCctActive:
            state.services.active.services.includes("CCT-FLOW") && !state.services.active.services.includes("ARC")
    };
}

const actions = {
    certificationInfo,
    companyPec,
    esmDiscover,
    esmOpenApp,
    fetchCctProvisioningInfo,
    findOwnManagerConnections,
    getAdempimentiConfig,
    getCompanies,
    getConfig,
    getItemApps,
    getWorkspaceId,
    getGdprLogRoleAssociation,
    partecipateTalks,
    resetAdempimentiConfig,
    resetCompanyLoad,
    uploadCert,
    verifyStatus
};

const composedHoc = compose(connect(mapStateToProps, actions), errorBoundary);

export default composedHoc(DashboardView);
