import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

import "./style.css";

import { Layout, Spin } from "antd";
import get from "lodash/get";
import moment from "moment";
import { includes } from "ramda";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import { Route } from "react-router-dom";
import { compose } from "redux";
import sha256 from "sha256";

import { logout, updateUserPassword } from "../../actions/auth";
import { certificationInfo } from "../../actions/certification";
import { loadCompany, resetCompanyLoad } from "../../actions/company";
import { editUser, listUsers } from "../../actions/company/users";
import { getLogo } from "../../actions/manage-logo/index";
import { getPermissions } from "../../actions/metering/permissions";
import { getNotificationsList, getNotificationsStatus } from "../../actions/ncs/read";
import {
    deleteNotificationsList,
    deleteSingleNotification,
    updateNotificationsList,
    updateSingleNotification
} from "../../actions/ncs/write";
import { listNotifications, resetNotifications } from "../../actions/notification/read";
import {
    acceptNotification,
    markNotificationAsUnread,
    readNotification,
    rejectNotification
} from "../../actions/notification/write";
import { getLastReleaseNoteDate } from "../../actions/releasenotes";
import { getCctConfig } from "../../actions/services/cct";
import { fetchCctProvisioningInfo } from "../../actions/services/cct/provisioning";
import { getInvoicingConfig } from "../../actions/services/invoicing";
import { fetchActiveServices, resetActiveServices } from "../../actions/services/service-subscription";
import { copyTokenToast } from "../../actions/toastNotifications";
import { loadUser } from "../../actions/user";
import Logo from "../../components/general/logo";
import Header from "../../components/header";
import SideMenu from "../../components/side-menu";
import { useEmbed } from "../../hooks/use-embed";
import { getCurrentCookieConsents } from "../../utils/cookie-consent-utils";
import { identifyUserAndAccount } from "../../utils/gainsight";
import { isItemAdmin } from "../../utils/get-roles";
import { getTags } from "../../utils/releasenotes";
import { Routes } from "./routes";

export const CompanyDashboard = ({
    acceptNotification,
    auth,
    certificationInfo,
    company,
    companyStatus,
    companyUsers,
    consents,
    copyTokenToast,
    deleteNotificationsList,
    deleteSingleNotification,
    editUser,
    isCctActive,
    getCctConfig,
    fetchActiveServices,
    fetchCctProvisioningInfo,
    getLastReleaseNoteDate,
    getLogo,
    getPermissions,
    getNotificationsList,
    getNotificationsStatus,
    getInvoicingConfig,
    isDigitalArchiveConfigured,
    isDigitalArchiveSmartConfigured,
    isLoadingCompany,
    item,
    lastReleaseNoteDate,
    listNotifications,
    listUsers,
    loadCompany,
    loadedReleaseNoteTags,
    loadingReleaseNotes,
    loadUser,
    location,
    loginAuth,
    logout,
    markNotificationAsUnread,
    match,
    meteringStatus,
    meteringPermissions,
    notifications,
    notificationsExist,
    notificationsList,
    notificationsListStatus,
    provisioningInfo,
    unreadNotifications,
    updateNotificationsList,
    updateSingleNotification,
    updateUserPassword,
    updateUserPswStatus,
    user,
    userEditStatus,
    readNotification,
    rejectNotification,
    resetCompanyLoad,
    resetNotifications,
    services
}) => {
    const [collapsed, setCollapsed] = useState(true);
    const [itemChanged, setItemChanged] = useState(true);
    const [loadingItem, setLoadingItem] = useState(true);
    const [showDigitalArchiveSmartAd, setShowDigitalArchiveSmartAd] = useState(undefined);
    const [isCctCredentialExpired, setIsCctPasswordExpired] = useState(undefined);
    const [isProvisioningNotValidated, setIsProvisioningNotValidated] = useState(undefined);
    const [isRdcToBeFixedTs, setIsRdcToBeFixedTs] = useState(undefined);
    const [isRdcToBeFixedWrongData, setIsRdcToBeFixedWrongData] = useState(undefined);

    const { isEmbed } = useEmbed();

    useEffect(() => {
        if (
            (companyStatus.ended && company.base && company.base.id === match.params.item) ||
            (companyStatus.error && companyStatus.errorInfo.itemId === match.params.item)
        ) {
            setLoadingItem(false);
        }
    }, [companyStatus, company.base, match.params.item]);

    useEffect(() => {
        const fatturaSmartMigrationUrl = get(services, "invoicing.getInvoicingConfig.fatturaSmartMigrationUrl");
        if (fatturaSmartMigrationUrl) {
            window.GAINSIGHT_GLOBAL_OPEN_FATTURA_SMART_MIGRATION_PAGE = () => {
                window.open(fatturaSmartMigrationUrl, "_blank");
            };
        }

        const { C0005: profilingCookiesAccepted } = getCurrentCookieConsents();
        const normalizeAndHash = s => s && sha256(s.toLowerCase().trim());
        const tsIdEmail = get(user, ["user", "profile", "tsid"]);
        const getItemFieldIfCookieAccepted = (field, defaultValue) =>
            profilingCookiesAccepted ? get(item, field) : defaultValue;

        let formattedAccountId = `ONEP_CORE_REGISTRY_ITEM_${get(item, "base.uuid").toLowerCase().trim()}`;
        let hashedVatNumber = normalizeAndHash(get(item, "base.identifier.vatNumber"));
        identifyUserAndAccount({
            user: {
                id: normalizeAndHash(tsIdEmail),
                email: profilingCookiesAccepted ? tsIdEmail : "Email",
                firstName: profilingCookiesAccepted ? get(user, ["user", "profile", "firstName"]) : "FirstName",
                lastName: profilingCookiesAccepted ? get(user, ["user", "profile", "lastName"]) : "LastName",
                ONEP_CORE_LEGAL_profilingConsent: profilingCookiesAccepted,
                ONEP_CORE_LEGAL_privacyConsent: consents.CORE_PRIVACY && consents.CORE_PRIVACY.accepted,
                ONEP_CORE_LEGAL_dataProcessingConsent:
                    consents.CORE_DATA_PROCESSING && consents.CORE_DATA_PROCESSING.accepted,
                ONEP_CORE_LEGAL_marketingConsent: consents.CORE_MARKETING && consents.CORE_MARKETING.accepted
            },
            account: {
                id: hashedVatNumber ? hashedVatNumber : normalizeAndHash(formattedAccountId),
                name: profilingCookiesAccepted ? get(item, "base.details.description") : "name",
                ONEP_CORE_REGISTRY_ITEM_id: getItemFieldIfCookieAccepted("base.uuid", "itemUuid"),
                ONEP_CORE_REGISTRY_ITEM_countryCode: get(item, "base.identifier.taxRegion"),
                ONEP_CORE_REGISTRY_ITEM_taxIdentifier: getItemFieldIfCookieAccepted("base.identifier.taxId", "taxId"),
                ONEP_CORE_REGISTRY_ITEM_vatIdentifier: getItemFieldIfCookieAccepted(
                    "base.identifier.vatNumber",
                    "vatNumber"
                ),
                ONEP_CORE_REGISTRY_ITEM_classifier: get(item, "base.details.classifier"), //
                ONEP_CORE_REGISTRY_ITEM_businessName: getItemFieldIfCookieAccepted(
                    "base.details.description",
                    "description"
                ),
                ONEP_CORE_REGISTRY_ITEM_name: getItemFieldIfCookieAccepted("base.details.firstName", "firstName"),
                ONEP_CORE_REGISTRY_ITEM_surname: getItemFieldIfCookieAccepted("base.details.lastName", "lastName")
            }
        });
    }, [
        loginAuth,
        item,
        services,
        showDigitalArchiveSmartAd,
        isCctCredentialExpired,
        isProvisioningNotValidated,
        isRdcToBeFixedTs,
        isRdcToBeFixedWrongData,
        user,
        consents.CORE_DATA_PROCESSING,
        consents.CORE_MARKETING,
        consents.CORE_PRIVACY
    ]);

    useEffect(() => {
        if (
            get(item, "base.details.classifier") !== undefined &&
            isDigitalArchiveSmartConfigured !== undefined &&
            isDigitalArchiveConfigured !== undefined &&
            services.active.services
        ) {
            const isStudio = get(item, "base.details.classifier") === "STUDIO";

            const isDigitalArchiveServiceActive =
                includes("CCT-FLOW", services.active.services) || (provisioningInfo && provisioningInfo.status);

            setShowDigitalArchiveSmartAd(
                isDigitalArchiveServiceActive &&
                    ((!isDigitalArchiveSmartConfigured && isStudio) ||
                        (!isStudio && !isDigitalArchiveConfigured && provisioningInfo.status === "initialized"))
            );
        }
    }, [loginAuth, isDigitalArchiveConfigured, isDigitalArchiveSmartConfigured, item, provisioningInfo, services]);

    useEffect(() => {
        const isDigitalArchiveServiceActive = includes("CCT-FLOW", services.active.services);

        if (isItemAdmin(match.params.item, user) && isDigitalArchiveServiceActive) {
            setIsCctPasswordExpired(
                !services.cct.getCctConfig.agyoFepa &&
                    services.cct.getCctConfig.passwordExpDate > 0 &&
                    moment(services.cct.getCctConfig.passwordExpDate).isBefore(moment())
            );
        }
    }, [services, match.params.item, user]);

    useEffect(() => {
        const isDigitalArchiveServiceActive =
            provisioningInfo && provisioningInfo.status && provisioningInfo.couponId === "1000";

        if (isDigitalArchiveServiceActive && isItemAdmin(match.params.item, user)) {
            setIsProvisioningNotValidated(provisioningInfo.status !== "validated");
        }
    }, [provisioningInfo, services, match.params.item, user]);

    useEffect(() => {
        const isDigitalArchiveServiceActive =
            includes("CCT-FLOW", services.active.services) ||
            (provisioningInfo && provisioningInfo.status && provisioningInfo.couponId === "1000");

        if (isDigitalArchiveServiceActive && isItemAdmin(match.params.item, user) && isDigitalArchiveConfigured) {
            setIsRdcToBeFixedTs(
                provisioningInfo.rdcToBeFixed === true && provisioningInfo.holder.taxId === "01641790702"
            );
        }
    }, [isDigitalArchiveConfigured, provisioningInfo, services, match.params.item, user]);

    useEffect(() => {
        const isDigitalArchiveServiceActive =
            includes("CCT-FLOW", services.active.services) ||
            (provisioningInfo && provisioningInfo.status && provisioningInfo.couponId === "1000");

        if (isDigitalArchiveServiceActive && isItemAdmin(match.params.item, user) && isDigitalArchiveConfigured) {
            setIsRdcToBeFixedWrongData(
                provisioningInfo.rdcToBeFixed === true &&
                    (!provisioningInfo.holder.taxId || provisioningInfo.holder.taxId !== "01641790702")
            );
        }
    }, [loginAuth, isDigitalArchiveConfigured, item, provisioningInfo, services, match.params.item, user]);

    useEffect(() => {
        setItemChanged(true);
    }, [match.params.item]);

    useEffect(() => {
        if (meteringStatus.enabled && itemChanged) {
            getPermissions(company.base.uuid);
        }
    }, [itemChanged, getPermissions, company.base, meteringStatus.enabled]);

    useEffect(() => {
        if (isItemAdmin(match.params.item, user)) {
            listNotifications(match.params.item);
        }

        return () => resetNotifications();
    }, [listNotifications, match.params.item, resetNotifications, user]);

    useEffect(() => {
        if (itemChanged) {
            fetchActiveServices(match.params.item);
            getInvoicingConfig(match.params.item);
            certificationInfo(match.params.item);

            if (isCctActive) {
                fetchCctProvisioningInfo(match.params.item);
                getCctConfig(match.params.item, match.params.item);
            }

            setItemChanged(false);
        }
    }, [
        itemChanged,
        certificationInfo,
        fetchActiveServices,
        loadCompany,
        match.params.item,
        getInvoicingConfig,
        fetchCctProvisioningInfo,
        getCctConfig,
        isCctActive
    ]);

    const itemUuid = company.base ? company.base.uuid : "";
    useEffect(() => {
        if (itemUuid && itemChanged) {
            getLogo(itemUuid);
            listUsers(true, true, itemUuid);
        }
    }, [getLogo, itemChanged, itemUuid, listUsers]);

    const tags = getTags(match.params.item ? services : null);
    useEffect(() => {
        const allTagsLoaded = tags.every(expectedTag =>
            loadedReleaseNoteTags.find(loadedTag => loadedTag === expectedTag)
        );
        if (!allTagsLoaded && !loadingReleaseNotes) {
            getLastReleaseNoteDate(tags);
        }
    }, [getLastReleaseNoteDate, loadedReleaseNoteTags, loadingReleaseNotes, tags]);

    const getNcsNotificationsList = useCallback(() => {
        if (user.user) {
            getNotificationsList(user.user.profile.ncsId);
        }
    }, [getNotificationsList, user]);

    const getNcsNotificationsExist = useCallback(() => {
        if (user.user) {
            getNotificationsStatus(user.user.profile.ncsId);
        }
    }, [getNotificationsStatus, user]);

    return !loadingItem && companyStatus.error && companyStatus.errorInfo.code === "404" ? (
        <Redirect to="/" />
    ) : isLoadingCompany ? (
        <div className="root-spinner-container">
            <Spin size="large" />
        </div>
    ) : (
        <Layout className="Root">
            {!isEmbed ? (
                <Layout.Sider
                    collapsible
                    trigger={null}
                    collapsed={collapsed}
                    style={{
                        background: "#152935"
                    }}
                >
                    <div className="Root-Icon">
                        <i
                            className="fas fa-bars Root-trigger"
                            onClick={() => {
                                sessionStorage.setItem("isOpenMenu", collapsed);
                                setCollapsed(!collapsed);
                            }}
                        />
                    </div>

                    {user.user && user.user.roles && services.active.availableToUser && company.base && (
                        <Route
                            path="/:item?/:menuItem*"
                            render={props => (
                                <SideMenu
                                    {...props}
                                    isStudio={company.base.details.classifier === "STUDIO"}
                                    isPerson={company.base.details.classifier === "PERSON"}
                                    meteringStatus={meteringStatus}
                                    meteringPermissions={meteringPermissions}
                                    services={services}
                                    user={user}
                                    lastReleaseNoteDate={lastReleaseNoteDate}
                                />
                            )}
                        />
                    )}
                </Layout.Sider>
            ) : null}
            <Layout>
                {!isEmbed ? (
                    <Layout.Header className="Root-header">
                        <div className={"Root-header-text-container"}>
                            <Logo white={true} height={30} />
                        </div>
                        <Header
                            auth={auth}
                            copyTokenToast={copyTokenToast}
                            onNotificationAccept={id => acceptNotification(id)}
                            company={company}
                            user={user}
                            logout={logout}
                            resetCompanyLoad={() => {
                                resetCompanyLoad();
                            }}
                            notifications={notifications}
                            notificationsCenterLink={`/${match.params.item}/notifications-center`}
                            notificationsExist={notificationsExist}
                            notificationsList={notificationsList}
                            notificationsListStatus={notificationsListStatus}
                            deleteNotificationsList={() => deleteNotificationsList(user.user.profile.ncsId)}
                            deleteSingleNotification={notificationId =>
                                deleteSingleNotification(user.user.profile.ncsId, notificationId)
                            }
                            getNotifications={getNcsNotificationsList}
                            getNotificationsExist={getNcsNotificationsExist}
                            updateNotificationsList={request => {
                                updateNotificationsList(user.user.profile.ncsId, request);
                            }}
                            updateSingleNotification={(notificationId, request) =>
                                updateSingleNotification(user.user.profile.ncsId, notificationId, request)
                            }
                            onNotificationReject={id => rejectNotification(id)}
                            onReadNotification={notificationId => readNotification(notificationId)}
                            onUnreadNotification={notificationId => markNotificationAsUnread(notificationId)}
                            unreadNotifications={unreadNotifications}
                            location={location}
                            editUser={editUser}
                            loadUser={loadUser}
                            updateUserPsw={updateUserPassword}
                            updateUserPswStatus={updateUserPswStatus}
                            userEditStatus={userEditStatus}
                            dashboardView={true}
                            users={companyUsers}
                        />
                    </Layout.Header>
                ) : null}
                <Layout.Content className="Root-content">
                    <Routes />
                </Layout.Content>
            </Layout>
        </Layout>
    );
};

function mapStateToProps(state, props) {
    return {
        auth: state.auth,
        item: state.companies.data[props.match.params.item] ? state.companies.data[props.match.params.item].item : {},
        consents: state.userConsents.terms,
        company: state.companies.data[props.match.params.item]
            ? state.companies.data[props.match.params.item].item
            : {},
        companyUsers: state.companies.data[props.match.params.item]
            ? state.companies.data[props.match.params.item].users
            : {},
        companyStatus: state.company.status,
        companies: state.companies,
        isDigitalArchiveConfigured:
            state.services.cct.provisioningInfo &&
            state.services.cct.provisioningInfo.provisioningInfo[props.match.params.item]
                ? state.services.cct.provisioningInfo.provisioningInfo[props.match.params.item].active
                : undefined,
        isDigitalArchiveSmartConfigured:
            state.services.cct.provisioningInfo &&
            state.services.cct.provisioningInfo.provisioningInfo[props.match.params.item]
                ? state.services.cct.provisioningInfo.provisioningInfo[props.match.params.item].contractType ===
                  "PROVISIONING_SMART"
                : undefined,
        loginAuth: state.auth.loginAuth,
        meteringStatus: state.metering.permissions.status,
        meteringPermissions: state.metering.permissions,
        notifications: state.notification.read.listNotifications.list.map(id => state.notification.read.results[id]),
        notificationsExist: state.ncs.notifications.exist,
        notificationsList: state.ncs.notifications.data,
        notificationsListStatus: state.ncs.notifications.status,
        unreadNotifications: state.notification.read.listNotifications.unreadNotifications,
        user: state.user,
        isCctActive:
            state.services.active.services.includes("CCT-FLOW") && !state.services.active.services.includes("ARC"),
        provisioningInfo: state.services.cct.provisioningInfo.provisioningInfo[props.match.params.item],
        services: state.services,
        lastReleaseNoteDate: state.releasenotes.lastReleaseNoteDate,
        loadedReleaseNoteTags: state.releasenotes.tags,
        loadingReleaseNotes: state.releasenotes.status.started,
        updateUserPswStatus: state.password.update.status || {},
        userEditStatus: state.manageUser.editUser.status || {}
    };
}

const actions = {
    acceptNotification,
    certificationInfo,
    copyTokenToast,
    editUser,
    fetchActiveServices,
    getCctConfig,
    getPermissions,
    getLastReleaseNoteDate,
    deleteSingleNotification,
    deleteNotificationsList,
    fetchCctProvisioningInfo,
    getNotificationsList,
    getNotificationsStatus,
    getInvoicingConfig,
    getLogo,
    updateSingleNotification,
    updateNotificationsList,
    listNotifications,
    listUsers,
    loadCompany,
    loadUser,
    logout,
    markNotificationAsUnread,
    readNotification,
    rejectNotification,
    resetActiveServices,
    resetCompanyLoad,
    resetNotifications,
    updateUserPassword
};

CompanyDashboard.propTypes = {
    auth: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    certificationInfo: PropTypes.func.isRequired,
    company: PropTypes.object,
    companyUsers: PropTypes.object,
    consents: PropTypes.object,
    copyTokenToast: PropTypes.func.isRequired,
    fetchActiveServices: PropTypes.func.isRequired,
    deleteSingleNotification: PropTypes.func.isRequired,
    deleteNotificationsList: PropTypes.func.isRequired,
    getNotificationsList: PropTypes.func.isRequired,
    getNotificationsStatus: PropTypes.func.isRequired,
    updateSingleNotification: PropTypes.func.isRequired,
    updateNotificationsList: PropTypes.func.isRequired,
    getPermissions: PropTypes.func.isRequired,
    getInvoicingConfig: PropTypes.func.isRequired,
    isLoadingCompany: PropTypes.bool,
    listUsers: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    menuItem: PropTypes.string,
    meteringStatus: PropTypes.object.isRequired,
    meteringPermissions: PropTypes.object.isRequired,
    notificationsExist: PropTypes.bool.isRequired,
    notificationsList: PropTypes.array,
    notificationsListStatus: PropTypes.object.isRequired,
    provisioningInfo: PropTypes.object,
    resetNotifications: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    getLastReleaseNoteDate: PropTypes.func.isRequired,
    lastReleaseNoteDate: PropTypes.object,
    services: PropTypes.object
};

const composedHoc = compose(withRouter, connect(mapStateToProps, actions));

export default composedHoc(CompanyDashboard);
